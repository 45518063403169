<template>
	<div class="news">
		<div class="banner">
			<div class="font">{{ $t('news.title') }}</div>
			<div class="item_box_title">
				<img
					src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmafcWSsa3GazhCLqr2yvWhcHDHZu8GrXkujkr4EWMMgwc"
					alt=""
				/>
				<span>
				{{ $t('news.News') }}
				</span>
			</div>
			<div class="body">
				<img src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmdL5hRcTcVFQFoyh6Gchod4sEhkCH3RnzTCAUMXbA3EJN" alt="" class="left">
				<div class="right">
					Once upon a time, in a future not far from our own, humanity had finally turned its gaze to the stars, and Mars became the new frontier. As the first settlers touched down on the red planet, they were not just pioneers in a barren landscape but adventurers stepping into the vibrant, digital realm of the......
				</div>
			</div>
			<div class="item_box_title">
				<img
					src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmafcWSsa3GazhCLqr2yvWhcHDHZu8GrXkujkr4EWMMgwc"
					alt=""
				/>
				<span>
				{{ $t('news.Announcements') }}
				</span>
			</div>
			<div class="news_body">
				<div class="box">
					<div class="item" @click="go(i)" v-for="(item, i) in blogList" :key="i">
						<img :src="locale == 'zh' ? item.cnImg : item.img" alt="" class="img" />
						<div class="title">
							{{ item.title }}
						</div>
						<div class="date">
							{{ item.date }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
// import { fetchData } from '@/api/index';

const { t, locale } = useI18n();
const blogList = ref([
	// {
	// 	img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmbTbuRT3EXCVF4Fga8cY78mDV2L37KaVvvGVL7vw68rVA",
	// 	cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmbDszcfgUyVrUHQKjYLwjQi2Mmy4xpMNm65WbMyskvWRu",
	// 	date: '2024/7/11',
	// 	title: t('blog.item1.title'),
	// 	topic: t('blog.item1.topic'),
	// },
	// {
	// 	img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmTvTrVmsgij42xFkuB7dcNfWSWuhgyM1NBYcERBxHkLAe",
	// 	cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmVMa7AAtv2e621X59ftngsnMkZGeAEcAtYxaQ2LLC4jCD",
	// 	date: '2024/7/13',
	// 	title: t('blog.item2.title'),
	// 	topic: t('blog.item2.topic'),
	// },
	// {
	// 	img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmbbiyVbR9FhmdFwk98xpVqaTvkb6556PGCyZd8nSFvSXb",
	// 	cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmWK9KVVWhCCsxHTqxvBjLLefuE4huumcei9e14YkK8SqM",
	// 	date: '2024/8/5',
	// 	title: t('blog.item3.title'),
	// 	topic: t('blog.item3.topic'),
	// },
	// {
	// 	img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmYCGh5NUHe4oECnFg28fZuc99pFb2so2iMUYcyuLsc4N2",
	// 	cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmcW5MVhPbdgw44VwRHwx9VJJVXJAfkd5CXdx61hBPAFgs",
	// 	date: '2024/7/10',
	// 	title: t('blog.item4.title'),
	// 	topic: t('blog.item4.topic'),
	// },
	// {
	// 	img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmT28fApG9BSK3wXzoYjsUqGeassEsid1EFjjKvwCUoGsJ",
	// 	cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmWHjvUCbCuGxyjWe3eXkTzjpysiaxrghgY9ToG32sUuFP",
	// 	date: '2024/7/12',
	// 	title: t('blog.item5.title'),
	// 	topic: t('blog.item5.topic'),
	// },
	// {
	// 	img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmVqdaBhZ6Hi6cKpo1BHZoZ64XMzzpTR6eKCvR7VwYjJA1",
	// 	cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmTV56unCyJqSzYXMMVWNPDxTB49rqok99vpbU5gYpjwx1",
	// 	date: '2024/7/19',
	// 	title: t('blog.item6.title'),
	// 	topic: t('blog.item6.topic'),
	// },
	// {
	// 	img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmSqxWmPaCLodE69Ueb7P9MESvioFYXc3tkAiRYiEr9B75",
	// 	cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmfAX4qkrkJKQR4o7ZMZFD4MUjss8yFHbdbC93kZ5gtgRq",
	// 	date: '2024/7/26',
	// 	title: t('blog.item7.title'),
	// 	topic: t('blog.item7.topic'),
	// },
	// {
	// 	img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmSF9v4DMFTrnPjUZAqcbcLu85UHsSRpr4RF9G3grQNJyn",
	// 	cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmYotzzLRjkNHG9AtRo52XzvqcNkFNyMF1msnmeGD4VF5G",
	// 	date: '2024/8/3',
	// 	title: t('blog.item8.title'),
	// 	topic: t('blog.item8.topic'),
	// },
	// {
	// 	img: '',
	// 	date: '',
	// 	title: '',
	// 	topic: '',
	// },
]);
const go = (item) => {
	window.open(item.linkUrl, '_blank');
};
console.log(locale.value)
let lang = 0
if(locale.value == "zh"){
	lang = 1
}else {
	lang = 0
}
// fetchData(lang) // axios请求在这里
// 	.then((res) => {
// 		console.log(res);
// 		// blogList.value = res.data;
// 	})
// 	.catch((err) => {
// 		console.log(err);
// 	});
</script>

<style scoped lang="scss">
@media screen and (min-width: 768px) {
	.news {
		width: 100%;
		overflow: auto;
		height: 100vh;
		background: #000000;
		color: #ffffff;
		margin-top: -80px;
		.banner {
			width: 100%;
			height: 730px;
			background: url('https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmSTd1iHypAS9Xj3n2kZ3jsnSKcpBMLtC59zwC9TFgpfn6/bk.png');
			background-size: 1920px 730px;
			display: flex;
			flex-direction: column;
			// justify-content: center;
			padding: 230px 360px 0;
			align-items: center;
			.font {
				width: 692px;
				color: #f5f5f5;
				font-size: 50px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				text-align: center;
				color: #CA8232;
				margin-bottom: 82px;
			}
			.item_box_title {
				width: 100%;
				font-weight: 700;
				font-size: 40px;
				margin: 58px 0 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 400;
				font-size: 56px;
				line-height: 70px;
				img {
					width: 31px;
					height: 31px;
					margin-right: 6px;
				}
			}
			.body {
				width: 100%;
				display: flex;
				justify-content: space-between;
				img {
					width: 892px;
					height: 410px;
				}
				.right {
					width: 272px;
					font-size: 18px;
					line-height: 33px;
				}
			}
			.news_body {
				width: 100%;
				.box {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					box-sizing: border-box;
					.item {
						max-width: 24%;
						width: 276px;
						height: 315px;
						color: rgba(101, 106, 111, 1);
						margin-bottom: 64px;
						cursor: pointer;
						box-sizing: border-box;
						img {
							width: 276px;
							height: 180px;
						}
						.title {
							width: 100%;
							font-size: 22px;
							font-weight: 500;
							color: rgba(255, 255, 255, 1);
							margin: 16px 0  20px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box; //作为弹性伸缩盒子模型显示。
							-webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
							-webkit-line-clamp: 3; //显示的行
						}
						.date {
							font-size: 12px;
							color: rgba(255, 255, 255, 1);
						}
					}
					.item:last-child {
						visibility: hidden !important;
						height: 0 !important;
						border: 0 !important;
						padding: 0 !important;
						margin: 0 !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.news {
		width: 100%;
		background: #000000;
		color: #ffffff;
		// margin-top: -80px;
		.banner {
			width: 100%;
			// height: 730px;
			background: url('https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmZVcm3WaWBLDCat5nrfbsjJXKduM8nuSHupW3w7y1zAdU') no-repeat;
			background-size: 375px 203px;
			display: flex;
			flex-direction: column;
			// justify-content: center;
			padding: 0 37px 0;
			align-items: center;
			.font {
				width: 210px;
				color: #f5f5f5;
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-align: center;
				color: #CA8232;
				margin: 85px 0 62px;
			}
			.item_box_title {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				font-weight: 500;
				font-size: 28px;
				line-height: 35px;
				margin-top: 82px;
				margin-bottom: 76px;
				text-align: center;
				img {
					width: 12px;
					height: 12px;
					margin-right: 6px;
				}

				width: 100%;
				text-align: center;
				font-weight: 600;
				font-size: 28px;
				margin: 58px 0 32px;
			}
			.body {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				img {
					width: 300px;
					height: 138px;
					margin-bottom: 15px;
				}
				.right {
					width: 300px;
					font-size: 12px;
					line-height: 22px;
					
				}
			}
			.news_body {
				width: 100%;
				.box {
					width: 100%;
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;
					justify-content: space-between;
					box-sizing: border-box;
					.item {
						// max-width: 24%;
						width: 300px;
						height: 307px;
						color: rgba(101, 106, 111, 1);
						margin-bottom: 64px;
						cursor: pointer;
						box-sizing: border-box;
						margin-bottom: 40px;
						img {
							width: 300px;
							height: 180px;
							border-radius: 8px;
						}
						.title {
							width: 100%;
							font-size: 20px;
							font-weight: 500;
							color: rgba(255, 255, 255, 1);
							margin: 19px 0 ;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box; //作为弹性伸缩盒子模型显示。
							-webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
							-webkit-line-clamp: 3; //显示的行
						}
						.date {
							font-size: 12px;
							font-weight: 400;
							color: rgba(255, 255, 255, 1);
						}
					}
					.item:last-child {
						visibility: hidden !important;
						height: 0 !important;
						border: 0 !important;
						padding: 0 !important;
						margin: 0 !important;
					}
				}
			}
		}
	}
}
</style>
